import io from "socket.io-client"
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
console.log(4,state.socket_server_url)
export default {
    install(Vue) {
        Vue.config.globalProperties.socket = 
             io(state.socket_server_url);

        
    }
}
