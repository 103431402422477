// import store from "@/state/store";

export default [


  {
    path: '/services',
    name: 'services',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/Services.vue"),
  },

  {
    path: '/mulim-services',
    name: 'mulim-services',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/mulim.vue"),
  },


  {
    path: '/coupons',
    name: 'coupons',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Coupons/CouponsPage.vue"),
  },


  {
    path: '/add-coupon',
    name: 'add-coupon',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Coupons/AddCoupon/index"),
  },
  {
    path: '/edit-coupon/:id',
    name: 'edit-coupon',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Coupons/EditCoupon/index"),
  },


  {
    path: '/',
    name: '',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Login/LoginPage.vue"),
  },

  {
    path: '/customers',
    name: 'customers',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/customers/index.vue"),
  },

 
  {
    path: '/banners',
    name: 'banners',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Banners/BannersPage"),
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {
      authRequired: true,
    },
    component: () => import("./old_views/dashboards/crypto/index.vue"),
  },
  {
    path: '/general-settings',
    name: 'general-settings',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Configs/ConfigsPage.vue"),
  },
  {
    path: "/accepted-orders",
    name: "accepted-orders",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/AcceptedOrdersPage/index.vue"),
  },
  {
    path: "/new-orders/order-deatiles/:id",
    name: "new-order-deatiles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/OrderDetails/index.vue"),
  },
  {
    path: "/accepted-orders/order-deatiles/:id",
    name: "accepted-order-deatiles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/OrderDetails/index.vue"),
  },
  {
    path: '/driver',
    name: 'driver',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/DriverPage.vue"),
  },
  {
    path: '/new-driver',
    name: 'new-driver',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/newDriver.vue"),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Login/LoginPage"),
  },

  {
    path: '/blog',
    name: 'blog',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Blog/BlogPage"),
  },


  {
    path: '/campaign-settings',
    name: 'campaign-settings',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/CampaignSettings/CampaignSettingsPage"),
  },


  {
    path: '/campaigns',
    name: 'campaigns',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Campaigns/CampaignsPage"),
  },


  {
    path: '/cards',
    name: 'cards',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cards/CardsPage"),
  },



  // {
  //   path: '/cities',
  //   name: 'cities',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Cities/CitiesPage.vue"),
  // },


  // {
  //   path: '/hoods',
  //   name: 'cities',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Cities/CitiesPage.vue"),
  // },

  {
    path: '/cateogries',
    name: 'cateogries',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cateogries/CateogriesPage"),
  },


  {
    path: '/complaines',
    name: 'complaines',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Complaines/ComplainesPage"),
  },


  {
    path: '/configs',
    name: 'configs',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Configs/ConfigsPage"),
  },


  // {
  //   path: '/contact-us',
  //   name: 'contact-us',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/ContactUs/ContactUsPage"),
  // },


  {
    path: '/contries',
    name: 'contries',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Contries/ContriesPage"),
  },


  {
    path: '/coupon-deatiles',
    name: 'coupon-deatiles',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/CouponDeatiles/CouponDeatilesPage"),
  },


  {
    path: '/coupon-discounts',
    name: 'coupon-discounts',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/CouponDiscounts/CouponDiscountsPage"),
  },


  {
    path: '/coupon-status',
    name: 'coupon-status',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/CouponStatus/CouponStatusPage"),
  },


  {
    path: '/coupons',
    name: 'coupons',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Coupons/CouponsPage"),
  },


  {
    path: '/currensies',
    name: 'currensies',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Currensies/CurrensiesPage"),
  },


  // {
  //   path: '/drivers',
  //   name: 'drivers',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Drivers/DriversPage"),
  // },


  {
    path: '/notification-settings',
    name: 'notification-settings',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/NotificationSettings/NotificationSettingsPage.vue"),
  },
  {
    path: '/public-notification',
    name: 'public-notification',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/NotificationSettings/publicNotifications.vue"),
  },


  {
    path: '/notifications',
    name: 'notifications',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Notifications/NotificationsPage.vue"),
  },
  



  {
    path: '/offer-details',
    name: 'offer-details',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OfferDetails/OfferDetailsPage"),
  },


  {
    path: '/offers',
    name: 'offers',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Offers/OffersPage"),
  },


  {
    path: '/order-deatiles',
    name: 'order-deatiles',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OrderDeatiles/OrderDeatilesPage.vue"),
  },


  {
    path: '/orders',
    name: 'orders',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/OrdersPage"),
  },

  {
    path: '/mulim-orders/:id',
    name: 'mulim_orders',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/mulimOrders"),
  },



  {
    path: '/orders-status',
    name: 'orders-status',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OrdersStatus/OrdersStatusPage"),
  },

  {
    path: "/new-orders",
    name: "new-orders",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/NewOrdersPage/index.vue"),
  },
  {
    path: "/accepted-orders",
    name: "accepted-orders",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/AcceptedOrdersPage/index.vue"),
  },

  
  {
    path: '/support-chats',
    name: 'support-chats',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SupportChats/SupportChatsPage"),
  },
  

  {
    path: '/support-questions',
    name: 'support-questions',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SupportQuestions/SupportQuestionsPage.vue"),
  },
  

  {
    path: '/support-requests',
    name: 'support-requests',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SupportRequests/SupportRequestsPage"),
  },
  

  {
    path: '/support-status',
    name: 'support-status',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SupportStatus/SupportStatusPage.vue"),
  },
  


  {
    path: "/new-orders/order-deatiles/:id",
    name: "new-order-deatiles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/OrderDetails/index.vue"),
  },
  {
    path: "/accepted-orders/order-deatiles/:id",
    name: "accepted-order-deatiles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/OrderDetails/index.vue"),
  },

  {
    path: "/order-deatiles/:id",
    name: "accepted-order-deatiles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/OrderDetails/index.vue"),
  },

  {
    path: "/orders-status",
    name: "orders-status",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OrdersStatus/OrdersStatusPage"),
  },

  {
    path: '/payment-method',
    name: 'payment-method',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/PaymentMethod/PaymentMethodPage"),
  },


  {
    path: '/product-ads',
    name: 'product-ads',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ProductAds/ProductAdsPage"),
  },


  {
    path: '/product-price-config',
    name: 'product-price-config',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ProductPriceConfig/ProductPriceConfigPage"),
  },


  {
    path: '/product-price-configs-types',
    name: 'product-price-configs-types',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ProductPriceConfigsTypes/ProductPriceConfigsTypesPage"),
  },


  {
    path: '/products',
    name: 'products',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Products/ProductsPage"),
  },


  {
    path: '/products-size',
    name: 'products-size',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ProductsSize/ProductsSizePage"),
  },


  {
    path: '/returned-orders',
    name: 'returned-orders',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ReturnedOrders/ReturnedOrdersPage"),
  },


  {
    path: '/roles',
    name: 'roles',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Roles/RolesPage"),
  },


  {
    path: '/sub-categories/:id/details/:table',
    name: 'sub_categories',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SubCategories/SubCategoriesPage.vue"),
  },

  {
    path: '/sub-questions/:id',
    name: 'sub-questions',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SupportQuestions/sub.vue"),
  },

  {
    path: '/subscriptions',
    name: 'subscriptions',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Subscriptions/SubscriptionsPage"),
  },


  {
    path: '/tech-support/:id',
    name: 'tech-support',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/chat/index.vue"),
  },

  {
    path: '/support-requests',
    name: 'support-requests',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SupportRequests/SupportRequestsPage"),
  },

  // {
  //   path: '/tech-support/:id',
  //   name: 'tech-support',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/TechSupport/TechSupportPage.vue"),
  // },
  

  {
    path: '/icons',
    name: 'icons',
    meta: {
      authRequired: true,
    },
    component: () => import("./old_views/icons/boxicons.vue"),
  },


  {
    path: '/themes',
    name: 'themes',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Themes/ThemesPage"),
  },


  {
    path: '/users',
    name: 'users',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/UsersPage"),
  },
  {
    path: '/user-profile/:id',
    name: 'user_profile',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/profile.vue"),
  },

  {
    path: '/vendors',
    name: 'vendors',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/VendorsPage.vue"),
  },

  {
    path: '/accounting/:id',
    name: 'accounting',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/Accounting.vue"),
  },

  {
    path: '/transactions/:id',
    name: 'transactions',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/Transactions.vue"),
  },
  {
    path: '/commissions',
    name: 'commissions',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/commissions/index.vue"),
  },
  {
    path: '/granted-commission',
    name: 'granted-commission',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/commissions/granted/index.vue"),
  },
  {
    path: '/new-vendors',
    name: 'new-vendors',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/newVendors.vue"),
  },

  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/withdraw.vue"),
  },
  {
    path: '/vendor-profile/:id',
    name: 'profile',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/details.vue"),
  },

  {
    path: "/cateogries",
    name: "cateogries",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cateogries/CateogriesPage"),
  },
  {
    path: "/add-category",
    name: "add-category",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cateogries/AddCategory/index.vue"),
  },
  {
    path: "/categories/products/:id",
    name: "categories_products",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cateogries/Products/index.vue"),
  },
  {
    path: '/none-vendor-profile/:id',
    name: 'none_vendor_profile',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/noneVendorDetails"),
  },
  {
    path: '/product/:id',
    name: 'single_product',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Products/ProductsPage.vue"),
  },

  {
    path: "/products",
    name: "products",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Products/ProductsPage"),
  },
  {
    path: "/products/product/:id",
    name: "products_profile",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Products/Product/index"),
  },
  {
    path: "/add-product/:id",
    name: "add-product",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Products/AddProduct/index.vue"),
  },

  {
    path: "/products-size",
    name: "products-size",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ProductsSize/ProductsSizePage"),
  },

  {
    path: "/returned-orders",
    name: "returned-orders",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ReturnedOrders/ReturnedOrdersPage"),
  },
  {
    path: '/driver-profile/:id',
    name: 'driver_profile',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/driverDetails.vue"),
  },
  {
    path: '/customer-profile/:id',
    name: 'customer_profile',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/customerDetails.vue"),
  },
  {

    path: '/users-dateils',
    name: 'users-dateils',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/UsersDateils/UsersDateilsPage"),
  },


  {
    path: '/users-roles',
    name: 'users-roles',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/UsersRoles/UsersRolesPage"),
  },


  {
    path: '/users-types',
    name: 'users-types',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/UsersTypes/UsersTypesPage"),
  },


  {
    path: '/vendor-balance',
    name: 'vendor-balance',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorBalance/VendorBalancePage"),
  },


  {
    path: '/vendor-brands',
    name: 'vendor-brands',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorBrands/VendorBrandsPage"),
  },


  {
    path: '/vendor-catogries',
    name: 'vendor-catogries',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorCatogries/VendorCatogriesPage"),
  },


  {
    path: '/vendor-currenices',
    name: 'vendor-currenices',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorCurrenices/VendorCurrenicesPage"),
  },


  {
    path: '/vendor-evaluation',
    name: 'vendor-evaluation',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorEvaluation/VendorEvaluationPage"),
  },


  {
    path: '/vendor-marketers',
    name: 'vendor-marketers',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorMarketers/VendorMarketersPage"),
  },


  {
    path: '/vendor-pages',
    name: 'vendor-pages',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorPages/VendorPagesPage"),
  },


  {
    path: '/vendor-sub-cateogries',
    name: 'vendor-sub-cateogries',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorSubCateogries/VendorSubCateogriesPage"),
  },


  {
    path: '/vendor-subscriptions',
    name: 'vendor-subscriptions',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorSubscriptions/VendorSubscriptionsPage"),
  },


  {
    path: '/vendor-themes',
    name: 'vendor-themes',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VendorThemes/VendorThemesPage"),
  },


]


